import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import App from '@components/App';
import AppInitWrapper from '@components/AppInitWrapper';
import { AmplifyAuthProvider } from 'src/auth/auth';
import '@amzn/meridian-tokens/base/font/amazon-ember.css';
import './index.scss';
import './i18n';
import { initRum } from '@amzn/aes-maestro-website-core/dist/metrics/clientMetric';
import { getStage } from 'src/utils/utils';

initRum(getStage());
/**
 * Configure amplify to authenticate user using cognito.
 */
AmplifyAuthProvider.initialize();

// The Suspense component causes React to display the fallback Loader until
// the i18n translation strings have been loaded.
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <AppInitWrapper>
      <React.Suspense>
        <App />
      </React.Suspense>
    </AppInitWrapper>
  </React.StrictMode>
);
